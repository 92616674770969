/* eslint-disable react/jsx-one-expression-per-line,react/jsx-indent */
import React from 'react';
import SmartCarousel from '../components/SmartCarousel';
import impulseRefImage from '../assets/images/impulse/impulse-ref.png';
import impulseImage1 from '../assets/images/impulse/impulse-1.jpg';
import impulseImage2 from '../assets/images/impulse/impulse-2.jpg';
import impulseImage3 from '../assets/images/impulse/impulse-3.jpg';
import impulseImage4 from '../assets/images/impulse/impulse-4.jpg';
import impulseImage5 from '../assets/images/impulse/impulse-5.jpg';
import impulseImage6 from '../assets/images/impulse/impulse-6.jpg';
import impulseImage7 from '../assets/images/impulse/impulse-7.png';
import impulseImage8 from '../assets/images/impulse/impulse-8.png';

import * as styles from '../css/impulse-details-content.css';
import PoweringImageContainer from '../components/PoweringImageContainer';
import PoweringImage from '../components/PoweringImage';
import { sectionIds } from './utils';
import DetailsHeaderText from '../components/DetailsHeaderText';
import DetailsText from '../components/DetailsText';
import DetailsTitleWithText from '../components/DetailsTitleWithText';
import Category from '../components/Category';
import DetailsList from '../components/DetailsList';
import SectionDetailsLink from '../components/SectionDetailsLink';
import ImportantText from '../components/ImportantText';

const ImpulseDetailsContent = () => (
    <>
        <DetailsHeaderText
            category="Stories"
            title="Supporting Electric Bus Operations - IMPULSE project"
        />
        <DetailsText>
            <p>
                <ImportantText>IMPULSE</ImportantText> aims to optimize the management of e-bus fleets through a
                software capable of processing
                real-time data on batteries’ status, vehicles and charging stations used, planned routes and specific,
                real-time transport network characteristics. The software aims to break down the technological and
                operational barriers to the electrification of bus fleets by combining 2 utilities: optimized vehicles’
                fleet management and batteries’ recharging operations planning. This will make using zero-emission
                vehicles more efficient and convenient for public transport operators.
            </p>
            <p>
                Its main characteristics are:
            </p>
            <DetailsList singleColumn>
                <DetailsTitleWithText
                    title="Real-time and Data-driven Optimization Methodologies:"
                    text={(
                        <>
                            Bus fleet management optimizes real-time data on vehicle usage,
                            battery status, scheduled routes, and transport network consumption profiles.
                        </>
                    )}
                />
                <DetailsTitleWithText
                    title="Up-to-date Network Consumption Profiles:"
                    text={(
                        <>
                            Vehicles’ consumption is monitored and forecast based on environmental and transport network-related
                            real-time data.
                        </>
                    )}
                />
                <DetailsTitleWithText
                    title="Service Interruption Risk Warning System:"
                    text={(
                        <>
                            If a route is at risk of being interrupted due to insufficient battery power, the fleet management and
                            driver staff will be alerted.
                        </>
                    )}
                />
                <DetailsTitleWithText
                    title="Pre- and post-implementation Scenario Comparison:"
                    text={(
                        <>
                            Collecting historical data enables comparison of pre- and post-implementation scenarios,
                            quantifying the impact on reducing service interruptions and operational costs variables.
                        </>
                    )}
                />
            </DetailsList>
            <p>
                The software is being tested in <ImportantText>Lisbon</ImportantText> (Portugal)
                and <ImportantText>Kadıköy</ImportantText> (Turkey) on different use cases for road
                public transport fleets, to assess its effectiveness and validate its scalability potential in
                several EU contexts.
            </p>
            <p>
                <ImportantText>IMPULSE</ImportantText> is co-funded by <ImportantText>EIT Urban Mobility</ImportantText> and has its
                 strategic objectives on smart and climate-neutral cities.
            </p>
            <SectionDetailsLink navigateTo={`/#${sectionIds.contactId}`}>
                Contact us
            </SectionDetailsLink>
        </DetailsText>
        <PoweringImageContainer>
            <PoweringImage className={styles.impulseLogo} gapSize="large" />
            <PoweringImage className={styles.eitLogo} gapSize="large" />
        </PoweringImageContainer>
        <SmartCarousel
            images={[
                impulseRefImage,
                impulseImage1,
                impulseImage2,
                impulseImage3,
                impulseImage4,
                impulseImage5,
                impulseImage6,
                impulseImage7,
                impulseImage8,
            ]}
        />
        <Category centered>Partners</Category>
        <PoweringImageContainer>
            <PoweringImage className={styles.carnetLogo} />
            <PoweringImage className={styles.realCityLogo} />
            <PoweringImage className={styles.kadikoyLogo} />
            <PoweringImage className={styles.carrisLogo} />
        </PoweringImageContainer>
    </>
);

export default ImpulseDetailsContent;
