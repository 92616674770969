import React from 'react';

export interface Props {
    image: string;
    video?: string;
    variant: 'left' | 'right';
}

const imageVariant = {
    left: {
        viewBox: '0 0 696 400',
        pathData: 'M0 0 L670 31 c 13 -1 25 9 26 22 A24 24 0 0 1 696 54 V360 c0 13 -10 24 -23 24 L0 402 z',
    },
    right: {
        viewBox: '0 0 700 405',
        pathData: 'M24 0 h 676 V405 L22 356 C10 355 0 345 0 332 V24 C0 11 11 0 24 0z',
    },
};

const ImageWithPath: React.FC<Props> = ({ video, image, variant }) => {
    const selectedVariant = imageVariant[variant];

    const clipPathStyle = { clipPath: `path("${selectedVariant.pathData}")` };
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox={selectedVariant.viewBox}>
            {!video && (
                <image style={clipPathStyle} width="100%" height="100%" preserveAspectRatio="none" xlinkHref={image} />
            )}
            {video && (
                <foreignObject width="100%" height="100%">
                    <video style={clipPathStyle} autoPlay muted loop poster={image}>
                        <source src={video} type="video/mp4" />
                    </video>
                </foreignObject>
            )}
        </svg>
    );
};

export default ImageWithPath;
